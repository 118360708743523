import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { SideMenuContextProvider } from "./contexts/sideMenu-context";
import "./i18n/config";
import "./index.css";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <SideMenuContextProvider>
      <App />
    </SideMenuContextProvider>
  </React.StrictMode>
);
