import { lazy, Suspense } from "react";

const Header = lazy(() => import("./components/header/Header"));
const Footer = lazy(() => import("./components/footer/Footer"));
const Main = lazy(() => import("./components/main/Main"));
const Menu = lazy(() => import("./components/menu/Menu"));
const ScrollToTop = lazy(() => import("./components/UI/ScrollToTop"));
const SelectLanguage = lazy(() => import("./components/UI/SelectLanguage"));

const Loading = () => (
  <div style={{ display: "grid", placeItems: "center", marginTop: 100 }}>
    Loading...
  </div>
);

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <SelectLanguage />
      <Menu />
      <Header />
      <Main />
      <Footer />
      <ScrollToTop />
    </Suspense>
  );
}

export default App;
