import React, { useState } from "react";

const SideMenuContext = React.createContext({
  visible: false,
  open: () => {},
  close: () => {},
});

export const SideMenuContextProvider = (props) => {
  const [visibility, setVisibility] = useState(false);
  const openHandler = () => setVisibility(true);
  const closeHandler = () => setVisibility(false);

  return (
    <SideMenuContext.Provider
      value={{ visible: visibility, open: openHandler, close: closeHandler }}
    >
      {props.children}
    </SideMenuContext.Provider>
  );
};

export default SideMenuContext;
