import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "el",
  lng: "el",
  resources: {
    en: { translations: require("./en.json") },
    el: { translations: require("./el.json") },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "el"];

export default i18n;
